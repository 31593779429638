import React from "react";
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import Layout from "./Layout"
import SEO from "./Seo"

const ContactPage = () => (
  <Layout>
    <SEO
      title="Connect with me"
      description="Do you think we can work together or do you want to offer me a project? Get in touch with me and we'll talk about it!"
    />
    <PageTransition>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 700,
          minHeight: `70vh`
        }}
      >
        <h1>Contact me!</h1>
        <div className="about-p">
          <p>If you want, you can contact me using the contact form below or sending an email to <a style={{textDecoration:'none', color: '#17141d'}} href="mailto:hello@ohilari.dev" alt="Send an email | Oriol Hilari">hello@ohilari.dev</a>. Or if you prefer, you can find me on <a style={{textDecoration:'none', color: '#17141d'}} href="https://www.linkedin.com/in/oriol-hilari/" alt="Linkedin | Oriol Hilari">Linkedin.</a></p>
        </div>
        {/* <form className="contact-form" method="post" action="https://formspree.io/hello@ohilari.dev"> */}
        <form className="contact-form" method="post" action="https://formspree.io/f/myybvvlq">
          <label>
            <h3 className="h3-form">Name</h3>
        <input type="text" name="name" id="name" required/>
          </label>
          <label>
          <h3 className="h3-form">Email</h3>
        <input type="email" name="email" id="email" required/>
          </label>
          <label>
          <h3 className="h3-form">Subject</h3>
        <input type="text" name="subject" id="subject" />
          </label>
          <label>
          <h3 className="h3-form">Message</h3>
        <textarea name="message" id="message" rows="5" required/>
          </label>
          <button className="submit-btn" type="submit">Send</button>
          {/* <input type="reset" value="Clear" /> */}
        </form>
      </div>
    </PageTransition>
  </Layout>
)

export default ContactPage




